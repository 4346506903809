/* global Cookies Foundation */

import URL from 'core-js/web/url';
const filCustomEvent = require('_fil/fil-global-frontend/trigger-analytics');

$(document).ready(function() {
  if ($('.fil-modal-popup > p > a.terms-of-conditions').length > 0) {
    $('.fil-modal-popup > p > a.terms-of-conditions').on('click', () => {
      $('.detail-compliance').addClass('detail-compliance-fixer');
      $('.fil-modal-popup').hide();
      $('.fil-modal-detail').show();
    });
  }

  $('.reveal').scroll(function() {
    if ($('.reveal').scrollTop() > 50) {
      $('.mobile-scroll-close').addClass('active');
    } else {
      $('.mobile-scroll-close').removeClass('active');
    }
  });

  /**
   *
   * Logic to check for compliance modals based on cookies
   *
   */
  if ($('.compliance-modal').length) {
    const complianceCheck = Cookies.get('FILCOMPLIANCECOOKIE');

    if (!complianceCheck) {
      $('.compliance-modal').foundation('open');
    }

    $('.fil-modal .compliance-buttons .primary').on(
      'click touch',
      setComplianceCookiesAndCloseModal
    );

    $('.fil-modal .detail-compliance-buttons .primary').on(
      'click touch',
      setComplianceCookiesAndCloseModal
    );
  }

  if ($('.editorial-compliance-modal').length) {
    const editorialComplianceCheck = Cookies.get('EDITORIALCOMPLIANCECOOKIE');
    if (!editorialComplianceCheck) {
      $('.editorial-compliance-modal').foundation('open');
    }

    $('.fil-modal .compliance-buttons .primary').on('click touch', function() {
      Cookies.set('EDITORIALCOMPLIANCECOOKIE', true);
      $('.editorial-compliance-modal').foundation('close');
    });
  }

  if ($('.editorial-compliance-modal').length) {
    const editorialComplianceCheck = Cookies.get('EDITORIALCOMPLIANCECOOKIE');
    if (!editorialComplianceCheck) {
      $('.editorial-compliance-modal').foundation('open');
    }

    $('.fil-modal .compliance-buttons .primary').on('click touch', function() {
      Cookies.set('EDITORIALCOMPLIANCECOOKIE', true);
      $('.editorial-compliance-modal').foundation('close');
    });
  }
  /**
   *
   * Logic to check if a user is already logged in. if so then open a windown
   *
   */
  $('.buy-funds').click(evt => {
    evt.preventDefault();
    let isin = $(evt.target).data('isin');
    window.buyFund(isin);
  });

  /**
   * Set up ISIN deep linking on the account opening anchor in the login from
   * tool modal
   *
   * @param {string} isin
   */
  const accountOpeningDeepLink = isin => {
    let anchor = $('.login-from-tool a[href*=account-opening]');
    let href = anchor.attr('href');

    if (href.indexOf('http://') === -1 && href.indexOf('https://') === -1) {
      href = window.location.origin + href;
    }

    if (href) {
      const url = new URL(href);
      url.hash = `/isin:${isin}`;
      anchor.attr('href', url);
    } else {
      // eslint-disable-next-line no-console
      console.warn('No account opening link found in login modal');
    }
  };

  /**
   * Open a login modal deep linking to the buy fund and open account journeys
   *
   * Note: This function *needs* to be available on window as it is called by
   * existing tools rendered in iframes
   *
   * @param {string} isin
   */
  window.buyFund = function(isin) {
    accountOpeningDeepLink(isin);

    let baseURL = window.loginCheckURL || window.location.toString();

    // Login check URL is probably configured to point to the API endpoint, so
    // strip xapi. from the domain to retrieve the URL of the frontend
    baseURL = baseURL.replace('xapi.', '');
    const secureRoute = '/secure/transaktionen/kaufen';

    const secureURL = new URL(secureRoute, baseURL);
    secureURL.protocol = 'https';
    secureURL.search = '';
    secureURL.searchParams.set('isin', isin);

    window.openLoginModal(secureURL.toString());
  };

  function getIsinsFromStorage(urlWithParams) {
    let isins = sessionStorage.getItem('isins');
    if (isins) {
      let isinsList = isins.split(',');

      let matches = urlWithParams.match(/[a-z\d]+=[a-z\d]+/gi);
      let count = matches.length;

      let url = '';

      for (let i = 0; i < isinsList.length; i++) {
        url += `&isin_${count + i + 1}=${isinsList[i]}`;
      }

      return `${urlWithParams}${url}`;
    }

    return urlWithParams;
  }

  window.openLoginModal = function(urlLogin) {
    let urlLoginMultipleFunds = getIsinsFromStorage(urlLogin);
    let urlRedirectLogin =
      urlLoginMultipleFunds.indexOf('xapi.') > -1
        ? urlLoginMultipleFunds.replace('xapi.', '')
        : urlLoginMultipleFunds;

    if ('withCredentials' in new XMLHttpRequest()) {
      $.ajax({
        url: `${parent.loginCheckURL}?_=${new Date().getTime()}`,
        type: 'GET',
        dataType: 'json',
        xhrFields: { withCredentials: true },
        success: function(data) {
          sessionStorage.removeItem('isins');
          if (data.accounts) {
            window.location.href = urlRedirectLogin;
          } else {
            window.revealLoginPopup(urlRedirectLogin);
          }
        },
        error: function() {
          window.revealLoginPopup(urlRedirectLogin);
        }
      });
    }
  };

  function buildTheAOLinkURL(accountOpeningURL) {
    let aoURL = '';
    let url = new URL(accountOpeningURL);
    let domain = url.hostname;
    let protocol = url.protocol;
    let matches = accountOpeningURL.match(/[a-z\d]+=[a-z\d]+/gi);
    let params = url.search;
    if (matches.length === 1) {
      aoURL = `${protocol}//${domain}/account-opening/index-new.html#/isin:${params.split('=')[1]}`;
      return aoURL;
    } else {
      let isins = [];
      let isinListFromParams = params.split('&');
      isinListFromParams.map(isin => {
        isins.push({ isin: isin.split('=')[1] });
      });
      aoURL = `${protocol}//${domain}/account-opening/index-new.html#/isin:${JSON.stringify(
        isins
      )}`;
      return encodeURI(aoURL);
    }
  }

  window.revealLoginPopup = function(urlLogin) {
    /**
     * Change the link that the login link to point to the secure site
     * including the approriate link.
     */

    const accountOpenLink = buildTheAOLinkURL(urlLogin);

    let accountOpeningLink = parent.$('.login-from-tool a.arrow-right-cta')[0];
    let loginCard = parent.$('.login-from-tool a.arrow-right-cta')[1];

    $(loginCard).attr('href', urlLogin);
    $(accountOpeningLink).attr('href', accountOpenLink);
    parent.$('.login-from-tool').foundation('open');
  };

  let revealModalCounter = 1;
  let revealOverlayZindex = 0;
  let modal;

  $(document).on('open.zf.reveal', '[data-reveal]', function() {
    modal = $(this);
    if (revealOverlayZindex === 0) {
      revealOverlayZindex = modal.parent().css('z-index');
    }

    revealModalCounter = revealModalCounter + 1;
    modal.parent().css('z-index', parseFloat(revealOverlayZindex) + revealModalCounter);
  });

  // TEMPORARY CARD SIZING HACK
  // Cards *always* render with the Foundation grid class medium-4. Howewever,
  // within modals they must instead use the classes medium-6 and fill out the
  // containing grid. This jQuery hack just swaps out the classes and sets the
  // styling as necessary.
  $('.reveal .card-container .card').css('width', '100%');
  $('.reveal .card-container')
    .removeClass('medium-4')
    .addClass('medium-6');

  //show welcome modal if 'welcome=true' in the location search parameter and the modal exist,
  const url = new URL(location.href);
  if (url.searchParams.get('welcome') == 'true') {
    let $welcomeModal = $('.welcome-modal');
    const modalId = url.searchParams.get('modalId');
    if (modalId) {
      $welcomeModal = $(`#${modalId}`);
    }
    if ($welcomeModal.length && $welcomeModal.foundation) {
      $welcomeModal.foundation('open');
    }
  }

  $('.modal__exit').on('click', function() {
    let modal_title = $(this)
      .closest('.fil-modal')
      .find('.fil-text .title-keyline')
      .text();

    filCustomEvent.callAnalytics(
      'event',
      'global.interactionEvent',
      'modal or pop-up',
      'closed',
      modal_title
    );
  });
});

function requalize() {
  Foundation.reInit('equalizer');
}

jQuery(document).on('open.zf.reveal', '[data-reveal]', function() {
  if ($('[data-equalizer]', this).length > 0) {
    setTimeout(requalize, 250);
  }
});

function setComplianceCookiesAndCloseModal() {
  Cookies.set('FILCOMPLIANCECOOKIE', true);
  $('.compliance-modal').foundation('close');
}

export function modalButtonEvent(modal_id) {
  let modal_title = $(`#${modal_id} .fil-text .title-keyline`).text();

  if (modal_id) {
    let page_name = window._dl.page_name.split(' ').includes('modal')
      ? window._dl.page_name
      : `${window._dl.page_name}: ${modal_title} modal`;

    window._dl = {
      ...window._dl,
      page_name: page_name,
      spa: true,
      message: []
    };

    document.dispatchEvent(
      new CustomEvent('analytics', {
        detail: {
          type: 'pageview'
        }
      })
    );
  }
}
